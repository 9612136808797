import { ref } from "vue";

export function useToggleState(init: boolean) {
    let flag = ref<boolean>(init);
    const toggle = () => flag.value = !flag.value;
    const yes = () => flag.value = true
    const no = () => flag.value = false

    return {
        flag,
        toggle,
        yes,
        no,
    }
}