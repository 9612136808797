<template>
  <div class="space">
    <div class="space-item">
      <el-button
        type="primary"
        class="float-left ml-4"
        @click="cloneDialogVisible = !cloneDialogVisible"
      >
        列表复制
      </el-button>
      <el-button
        type="primary"
        class="float-right mr-4"
        @click="refreshUsersWithLoading"
        >查询</el-button
      >
      <el-input
        class="float-right mr-4"
        style="width: 300px"
        v-model="search"
        placeholder="请输入用户名"
        @keydown.enter="refreshUsersWithLoading"
      ></el-input>
    </div>
    <div class="space-item flex flex-1 flex-column">
      <div class="radius-card" v-loading="loading">
        <el-scrollbar ref="scroll">
          <div class="pa-6">
            <el-table :data="users" ref="table" style="width: 100%">
              <el-table-column align="center" type="index" label="编号" />
              <el-table-column align="center" prop="account" label="用户名" />
              <el-table-column align="center" prop="tel" label="手机号" />
              <el-table-column align="center" prop="qq" label="qq" />
              <el-table-column align="center" prop="regDate" label="注册日期" />
              <el-table-column align="center" prop="expDate" label="到期日期" />
              <el-table-column align="center" prop="state" label="状态">
                <template #default="scope">
                  <el-tag :type="formatState(scope.row).type">
                    {{ formatState(scope.row).value }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column align="center" width="250" label="操作">
                <template #default="scope">
                  <el-button
                    @click="settings(scope.$index)"
                    size="mini"
                    @click.stop
                    >设置</el-button
                  >
                  <el-button
                    :disabled="scope.row.state === 0"
                    type="success"
                    @click="audit(scope.$index)"
                    size="mini"
                    @click.stop
                    >审计</el-button
                  >
                  <el-button
                    :disabled="scope.row.state === 3 || scope.row.state === 0"
                    type="primary"
                    size="mini"
                    @click="auditTest(scope.$index)"
                    @click.stop
                    >测试</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="flex justify-content-center align-item-center">
            <el-pagination
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              v-model:currentPage="pageIndex"
              @current-change="changePage"
              hide-on-single-page
            >
            </el-pagination>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
  <CloneUserDialog
    v-model="cloneDialogVisible"
    @submit="submitClone"
  ></CloneUserDialog>
</template>

<script>
import * as Api from "@/api";
import CloneUserDialog from "@/components/CloneUserDialog.vue";
export default {
  name: "user-admin",
  data() {
    return {
      showDetail: false,
      showEdit: false,
      search: "",
      users: [],
      total: 0,
      pageSize: 10,
      pageIndex: 1,
      loading: true,
      cloneDialogVisible: false
    };
  },
  methods: {
    refreshUsers() {
      this.loading = true;
      return Api.getUsersApi(this.pageIndex, this.pageSize, this.search)
        .then(({ data }) => {
          this.total = data.data.total;
          this.users = data.data.users;
          return data;
        })
        .finally(() => (this.loading = false));
    },

    refreshUsersWithLoading() {
      this.refreshUsers();
    },
    changePage(index) {
      this.pageIndex = index;
      this.refreshUsersWithLoading();
    },
    settings(index) {
      this.$router.push("/user-admin/settings/" + this.users[index].account);
    },
    formatState(row) {
      return Api.processUserState(row.state);
    },
    audit(index) {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)"
      });
      Api.auditUserApi(this.users[index].id)
        .then(({ data }) => {
          Api.sucessMessage(data.errorMessage);
          this.users[index].state = 0;
        })
        .finally(loading.close);
    },
    auditTest(index) {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)"
      });
      Api.auditTestUserApi(this.users[index].id)
        .then(({ data }) => {
          Api.sucessMessage(data.errorMessage);
          this.users[index].state = data.data.state;
          this.users[index].expDate = data.data.expDate;
        })
        .finally(loading.close);
    },
    submitClone(form) {
      Api.cloneUserApi(form)
        .then(res => {
          Api.sucessMessage("复制成功");
        })
        .finally(() => {
          this.cloneDialogVisible = false;
        });
    }
  },

  mounted() {
    this.refreshUsers();
  },
  components: {
    CloneUserDialog
  }
};
</script>
