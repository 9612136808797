import _ from 'underscore'
import * as ApiType from './api.type'
import dayjs from 'dayjs';

export function processTemplates (gameList: ApiType.GameList) {
    let templates = []
    templates.push({
        label: "未选择模板",
        value: 0,
    })
    gameList.forEach((v) => {
        templates.push({
            label: v.serverName,
            value: v.id,
        })
    })
    return templates;
}



export function processUserState(state: number) {
    switch(state) {
        case 0: return {value: '正常', type: 'success'}
        case 1: return {value: '禁用', type: 'danger'}
        case 2: return {value: '审核', type: 'warning'}
        case 3: return {value: '测试', type: 'primary'}
        default: return {value: '', type: ''}
    }
}

export function processDateRange(day: number) {
    return [
        dayjs().subtract(day - 1, 'day').format('YYYY-MM-DD'), 
        dayjs().format('YYYY-MM-DD')
    ]
}

export function formatDate(date: string) {
    return dayjs(date).format('YYYY-MM-DD')
}


export function processConnectData() {
    return {
        0: {
            text: '未检测',
        },
        1: {
            text: '连接',
        },
        2: {
            text: '故障',
        },
    }
}
