
import * as ApiType from './api.type'
import { ElMessage } from 'element-plus'
import _ from 'underscore'

const tokenKey = 'Token'

export function errorMessage(msg: string | null | undefined, defaultMsg: string) {
    ElMessage({
        type: 'error',
        message: _.isNull(msg) || _.isUndefined(msg) ? defaultMsg : msg
    })
}

export function sucessMessage(msg?: string) {
    if(_.isUndefined(msg)) return
    ElMessage({
        type: 'success',
        message: msg
    })
}

export function warnMessage(msg?: string) {
    if(_.isUndefined(msg)) return
    ElMessage({
        type: 'warning',
        message: msg
    })
}

export function setToken(result: ApiType.AnyResult) {
    if (result.isSuccess) {
        localStorage.setItem(tokenKey, result.data!)
    }
}

export function getToken() {
    return localStorage.getItem(tokenKey)
}

export function removeToken() {
    return localStorage.removeItem(tokenKey)
}

export function getSearchParams(obj: ApiType.IndexData<any>) {
    const params = new URLSearchParams();
    Object.keys(obj).forEach((key) => {
        params.set(key, obj[key])
    })
    return params.toString()
}