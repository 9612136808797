<template>
  <el-form ref="form" :rules="rules" :model="form" label-width="80px" style="width:400px;margin-top:100px;" :hide-required-asterisk="true">
      <el-form-item label="旧密码：" prop="oldPwd">
        <el-input type="password" v-model="form.oldPwd"></el-input>
      </el-form-item>
      <el-form-item label="新密码：" prop="newPwd">
        <el-input type="password" v-model="form.newPwd" autocomplete="new-password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" prop="checkPwd">
        <el-input type="password" v-model="form.checkPwd" autocomplete="new-password"></el-input>
      </el-form-item>
      <el-row class="mt-6">
        <el-button type="primary" style="width:100%;" @click="submit" class="login-btn" :loading="loading">修改密码</el-button>
      </el-row>
    </el-form>
</template>

<script>
import * as Api from "@/api";
export default {
  name: "password",
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.checkPwd !== "") {
          this.$refs.form.validateField("checkPwd");
        }
        callback();
      }
    };
    const validateCheckPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      form: {
        oldPwd: "",
        newPwd: "",
        checkPwd: "",
      },
      rules: {
        oldPwd: { required: true, message: "请输入旧密码", trigger: "blur" },
        newPwd: { validator: validatePass, trigger: "blur" },
        checkPwd: { validator: validateCheckPass, trigger: "blur" },
      },
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.change();
        }
      });
    },
    change() {
      Api.changePasswordApi(this.form)
        .then(({ data }) => {
          Api.sucessMessage(data.errorMessage);
          this.$router.replace("/login");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>