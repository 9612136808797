import axios, { AxiosResponse } from 'axios'

import * as ApiType from './api.type'
import * as apiHelper from './apiHelper'
import router from '@/router'
import * as Api from './api'
import * as Process from './process'
import { ref } from 'vue'

// 添加响应拦截器
Api.api.interceptors.response.use(function (response: AxiosResponse<ApiType.AnyResult>) {
    let data: ApiType.AnyResult = response.data
    if(response.data.isSuccess) {
        return response
    }
    apiHelper.errorMessage(data.errorMessage, '未知错误')
    throw response
}, function (error) {
    if (error.response && error.response.status === 401) {
        router.replace('/login')
    }

    if(error.code === 'ECONNABORTED') {
        apiHelper.errorMessage(null, '网络错误')
    }
    return Promise.reject(error);
});

export function login(form: ApiType.LoginRequest) {
    return Api.loginApi(form)
        .then((response) => {
            apiHelper.setToken(response.data)
            Api.api.defaults.headers["Authorization"] = apiHelper.getToken()
            return response.data
        })
}

export function getUserInfoRef() {
    let user = ref<ApiType.UserInfo>()
    Api.getUserInfoApi()
        .then(({ data: result }) => {
            user.value = result.data!
        })
    return user
}

export function getGameListRef() {
    let games = ref<Array<ApiType.Game>>()
    const loading = ref<boolean>(true);
    Api.getGameListApi()
        .then(({ data }) => {
            games.value = data.data
        })
        .finally(() => {
            loading.value = false
        })
    return {
        games,
        loading
    }
}


export function getGameBasicRef(id: string) {
    let settings = ref<ApiType.GameBasic>()
    Api.getGameBasicApi(id)
        .then(({ data }) => {
            settings.value = data.data
        })
    return settings
}
export function getGameBasicWithBackupRef(id: string) {
    let basic = ref<ApiType.GameBasic>()
    let backup: Partial<ApiType.GameBasic> = {};
    Api.getGameBasicApi(id)
        .then(({ data }) => {
            basic.value = data.data;
            backup = Object.assign(backup, data.data);
        })
    return {
        basic,
        backup
    }
}

export function getGamePluginWithBackupRef(id: string) {
    let plugin = ref<ApiType.GamePlugin>()
    let backup: Partial<ApiType.GamePlugin> = {};
    Api.getGamePluginApi(id)
        .then(({ data }: AxiosResponse<ApiType.GamePluginResult>) => {
            plugin.value = data.data;
            backup = Object.assign(backup, data.data);
        })
    return {
        plugin,
        backup
    }
}

export function getGamePluginRef(id: string) {
    let plugin = ref<ApiType.GamePlugin>()
    Api.getGamePluginApi(id)
        .then(({ data }: AxiosResponse<ApiType.GamePluginResult>) => {
            plugin.value = data.data
        })
    return plugin
}

export function getGameCustomGoldRef(id: string) {
    let golds = ref<ApiType.GameCustomGolds>()
    const loading = ref<boolean>(true);
    Api.getGameCustomGoldApi(id)
        .then(({ data }) => {
            golds.value = data.data
        })
        .finally(() => {
            loading.value = false
        })
    return {
        golds,
        loading
    }
}

export function getGameCustomButtonRef(id: string) {
    const buttons = ref<ApiType.GameCustombuttons>()
    const loading = ref<boolean>(true);
    Api.getGameCustomButtonApi(id)
        .then(({ data }) => {
            buttons.value = data.data
        })
        .finally(() => {
            loading.value = false
        })
    return {
        buttons,
        loading
    }
}
// TODO: 类型
export function getGameNewButtonRef(id: string) {
    const buttons = ref<ApiType.GameNewButton[]>();
    const loading = ref<boolean>(true);
    Api.getGameNewButtonApi(id)
    .then(({data}) => {
        buttons.value = data.data!;
    })
    .finally(() => {
        loading.value = false;
    })
    return {
        buttons,
        loading
    }
}

export function getGameActivityRef(id: string) {
    let activity = ref<ApiType.GameActivity>()
    Api.getGameActivityApi(id)
        .then(({ data }) => {
            activity.value = data.data
        })

    return activity
}
export function getGameActivityWithBackupRef(id: string) {
    let activity = ref<ApiType.GameActivity>()
    let backup: Partial<ApiType.GameActivity> = {};
    Api.getGameActivityApi(id)
        .then(({ data }) => {
            activity.value = data.data
            backup = Object.assign(backup, data.data);
        })
    return {
        activity,
        backup
    }
}

export function getGameFeaturesRef(id: string) {
    let features = ref<ApiType.GameFeatures>()
    Api.getGameFeaturesApi(id)
        .then(({ data }) => {
            features.value = data.data
        })
    return features
}
export function getGameFeaturesWithBackupRef(id: string) {
    let features = ref<ApiType.GameFeatures>()
    let backup: Partial<ApiType.GameFeatures> = {};
    Api.getGameFeaturesApi(id)
        .then(({ data }) => {
            features.value = data.data;
            backup = Object.assign(backup, data.data);
        })
    return {
        features,
        backup
    }
}

export function getTemplatesRef() {
    let templates = ref<ReturnType<typeof Process.processTemplates>>([])
    Api.getGameListApi()
    .then(({data}) => {
        templates.value = Process.processTemplates(data.data!)
    })
    return templates
}

export function deleteGames(games: ApiType.GameList) {
    let results = games.map(v => {
        return v.id
    })
    return Api.deleteGamesApi(results)
}

export function getUserInfoByNameRef(username: string) {
    let user = ref<any>()
    Api.getUserInfoByNameApi(username)
    .then(({data}) => {
        user.value = data.data
    })
    return user
}

export function getOnlineListRef() {
    const onlineList = ref<ApiType.OnlineList>();
    const loading = ref<boolean>(true)
    Api.getOnlineListApi()
    .then(({data}) => {
        onlineList.value = data.data  
    })
    .finally(() => {
        loading.value = false
    })
    return {
        onlineList,
        loading
    }
}

export function logout() {
    Api.api.defaults.headers["Authorization"] = "";
    apiHelper.removeToken();
}