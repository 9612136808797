<template>
  <el-card class="radius-20 mb-4" v-loading="loading">
    <div style="position:relative;">
      <div style="text-align:right;">
        <el-select v-model="device" size="small" style="width:120px;" @change="setStatistic">
          <el-option label="全部" :value="0"></el-option>
          <el-option label="安卓" :value="1"></el-option>
          <el-option label="IOS" :value="2"></el-option>
          <el-option label="其他" :value="3"></el-option>
        </el-select>
        <!-- &nbsp;
        <el-select v-model="game" size="small" @change="setStatistic">
          <el-option :key="item.id" v-for="item in games" :label="item.serverName" :value="item.id"></el-option>
        </el-select> -->
        &nbsp;
        <el-date-picker v-model="value" type="daterange" size="small" :clearable="false" value-format="YYYY-MM-DD" :default-time="[new Date(), new Date()]" :disabled="disableCustomDate" @change="setStatistic" />
        &nbsp;
        <el-select v-model="day" size="small" style="width:120px;" @change="changeDay">
          <el-option label="最近七天" :value="7"></el-option>
          <el-option label="最近三十天" :value="30"></el-option>
          <el-option label="自定义" :value="-1"></el-option>
        </el-select>
      </div>
      <div style="width:auto;height:400px;" id="PlayerLoginStatistic" ref="PlayerLoginStatistic"></div>
    </div>
  </el-card>
</template>

<script>
import * as echarts from "echarts";
import { initPlayerLoginStatisticSetting } from "@/utils/chartOptions";
import { ref } from "vue";
import * as Api from "@/api";
export default {
  props: {
    day: {
      type: Number,
      default: 7,
    },
    device: {
      type: Number,
      default: 0,
    },
  },
  customData: {
    statisticSetting: undefined,
  },
  setup() {
    return {
      value: ref([]),
      disableCustomDate: ref(true),
      loading: ref(true)
    };
  },
  methods: {
    setStatistic() {
      this.loading = true,
      Api.getPlayerLoginStatisticsApi({
        starTime: this.value[0],
        endTime: this.value[1],
        deviceType: this.device,
        gameZone: 0, // all
      }).then((response) => {
        const data = response.data.data;
        this.$options.customData.statisticSetting.setSourceWithUnion(
          ["date", "玩家IP", "玩家PV", "玩家UV", "玩家日新增"],
          data.listdate,
          data.playerStatistic["玩家IP"],
          data.playerStatistic["玩家PV"],
          data.playerStatistic["玩家UV"],
          data.playerStatistic["玩家日新增"]
        );
        this.$options.customData.statisticSetting.render();
        
      })
      .finally(this.loaded)
    },
    changeDay() {
      if (this.day < 0) {
        this.disableCustomDate = false;
        return;
      }
      this.disableCustomDate = true;
      this.value = Api.processDateRange(this.day);
      this.setStatistic();
    },
    loaded() {
      if (this.loading) this.loading = false;
    },
  },
  mounted() {
    const chart = echarts.init(this.$refs.PlayerLoginStatistic);
    this.$options.customData.statisticSetting =
      initPlayerLoginStatisticSetting(chart);
    this.changeDay();
  },
  unmounted() {
    this.$options.customData.statisticSetting.dispose();
    this.$options.customData.statisticSetting = undefined
  },
};
</script>