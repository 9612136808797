<template>
  <div class="space user">
    <div class="space-item" style="height:41px">
    </div>
    <div class="space-item">
      <el-card class="radius-20">
        <el-descriptions border :column="1" v-if="getUser()">
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-user"></i>
              用户名
            </template>
            {{ getUser().account }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-phone"></i>
              手机号
            </template>
            {{ getUser().tel }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template #label> QQ </template>
            {{ getUser().qq }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template #label> 列表数量 </template>
            {{ getUser().listCount }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template #label> 最大人数 </template>
            {{ getUser().maxUser }}
          </el-descriptions-item>

          <el-descriptions-item>
            <template #label>
              <i class="el-icon-date"></i>
              注册日期
            </template>
            {{ getUser().regDate }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label>
              <i class="el-icon-date"></i>
              到期日期
            </template>
            {{ getUser().expDate }}
          </el-descriptions-item>
          <el-descriptions-item label="上浮比例">
            {{ getUser().increaseRatio }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template #label> 动态分配在线人数 </template>
            <el-switch
              v-model="getUser().dynamicDiyUser"
              @change="changeDyn"
              :disabled="isTest()"
            ></el-switch>
          </el-descriptions-item>
        </el-descriptions>
        <el-skeleton :rows="5" animated v-else />
      </el-card>
    </div>
  </div>
</template>
<script>
import * as Api from "@/api";
export default {
  name: "user",
  data() {
    return {
      rechargeDialog: false,
    };
  },
  methods: {
    openRechargeDialog() {
      this.$refs.rechargeDialog.form.account = this.user.account;
      this.rechargeDialog = true;
    },
    recharge(form) {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)",
      });
      Api.rechargeApi(form)
        .then(({ data }) => {
          Api.sucessMessage("充值成功");
        })
        .finally(() => {
          this.rechargeDialog = false;
          loading.close();
        });
    },
    changeDyn(value) {
      Api.setDynamicAllocationApi(value);
    },
    getUser() {
      return this.$store.state.user
    },
    isTest() {
      return this.$store.state.user !== undefined && this.$store.state.user.state === 3
    }
  },
};
</script>
<style lang="scss">
.user tr td:nth-child(1) {
  width: 200px;
}
</style>
