<template>
  <div class="space">
    <div class="space-item" style="text-align: right;height:40px;">
      <el-button plain @click="openAddGameDialog" class="mx-2"  :disabled="isTest()">
        <i class="el-icon-plus"></i>
        添加游戏
      </el-button>
      <el-button type="danger" @click="deleteGame" @focus="focus" :disabled="isTest() || disableDelBtn" class="mr-4">
        <i class="el-icon-delete"></i>
        删除
      </el-button>
    </div>
    <div class="space-item flex flex-1 flex-column">
      <div class="radius-card" v-loading="loading">
        <el-scrollbar ref="scroll">
          <div class="pa-6">
            <el-table border :data="games" ref="table" style="width: 100%" @row-click="rowClick" @selection-change="handleSelectionChange">
              <el-table-column type="selection" align="center" />
              <el-table-column align="center" type="index" label="编号" width="80" />
              <el-table-column align="center" prop="serverName" label="游戏名称" />
              <el-table-column align="center" prop="serverAddr" label="服务器地址" />
              <el-table-column align="center" prop="port" label="服务端口" width="100px"/>
              <el-table-column align="center" prop="passWord" label="验证密码" width="100px"/>
              <el-table-column align="center" width="120px" prop="showTop" label="显示顺序" />
              <el-table-column align="center" prop="showDate" label="定时时间" :formatter="formatDate" />
              <el-table-column align="center" prop="fixedCount" label="固定数量" :formatter="formatFixedCount" width="100"/>
              <el-table-column align="center" prop="isConnection" label="状态">
                <template #default="scope">
                  <connect-text :type="scope.row.isConnection" :data="processConnectData()" />
                </template>
              </el-table-column>
              <el-table-column align="center" prop="groupName" label="分组" />
              <el-table-column align="center" label="操作" width="160">
                <template #default="scope">
                  <el-button @click="openEditGameDialog(scope.row)" size="mini" @focus="focus" @click.stop :disabled="isTest()">编辑</el-button>
                  <el-button @click="setGame(scope.$index)" size="mini" @click.stop type="primary">设置</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-scrollbar>
      </div>
    </div>
    <ServerInfoDialog ref="serverInfo" @post="addGame" @put="modifyGame" @changeTemplate="changeTemplate" />
  </div>
</template>

<script>
import ServerInfoDialog from "@/components/ServerInfoDialog.vue";
import ConnectText from '@/components/ConnectText.vue';
import _ from "underscore";
import * as Api from "@/api";
export default {
  name: "game",
  setup() {
    const { games, loading } = Api.getGameListRef();
    const processConnectData  = Api.processConnectData;
    return {
      games,
      loading,
      processConnectData
    };
  },
  data() {
    return {
      multipleSelection: [],
      disableDelBtn: true,
    };
  },
  methods: {
    openAddGameDialog() {
      let serverInfo = this.$refs.serverInfo;
      serverInfo.setTitle("添加游戏");
      serverInfo.setMethod("post");
      serverInfo.setTemplates(Api.processTemplates(this.games));
      serverInfo.open();
    },
    openEditGameDialog(row) {
      let serverInfo = this.$refs.serverInfo;
      serverInfo.setTitle("编辑游戏");
      serverInfo.setMethod("put");
      serverInfo.setData(row);
      serverInfo.setTemplates(null);
      serverInfo.open();
    },
    openLogDialog(e) {
      this.$refs.log.open();
    },
    deleteGame() {
      this.$confirm("此操作将删除该游戏, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          background: "rgba(0, 0, 0, 0.7)",
        });
        Api.deleteGames(this.multipleSelection)
          .then(() => {
            return this.refreshGames();
          })
          .then(() => {
            Api.sucessMessage("操作成功");
          })
          .finally(loading.close);
      });
    },
    setGame(index) {
      this.$router.push("/game/settings/" + this.games[index].id);
    },
    formatDate(row, _column, cellValue, _index) {
      return row.isShowDate ? cellValue : "-";
    },
    formatFixedCount(row, _column, cellValue, _index) {
      return row.isFixedCount ? cellValue : "-";
    },

    rowClick(row) {
      this.$refs.table.toggleRowSelection(row);
    },
    handleSelectionChange(rows) {
      this.multipleSelection = rows;
      this.handleDisableDelBtn(rows.length);
    },
    // 当游戏列表编辑后导致重新排序，但是button会被复用
    focus(e) {
      e.target.blur();
    },
    refreshGames() {
      return Api.getGameListApi().then(({ data }) => {
        this.games = data.data;
      });
    },
    handleDisableDelBtn(length) {
      if (length === 0) {
        this.disableDelBtn = true;
      } else {
        this.disableDelBtn = false;
      }
    },
    addGame(form) {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)",
      });
      Api.addGameApi(form)
        .then(() => {
          this.refreshGames();
        })
        .then(() => {
          Api.sucessMessage("操作成功");
        })
        .finally(() => {
          loading.close();
          this.$refs.serverInfo.cancel();
        });
    },
    modifyGame(form) {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)",
      });
      Api.modifyGameApi(form)
        .then(() => {
          return this.refreshGames();
        })
        .then(() => {
          Api.sucessMessage("操作成功");
        })
        .finally(() => {
          loading.close();
          this.$refs.serverInfo.cancel();
        });
    },
    changeTemplate(index) {
      let serverInfo = this.$refs.serverInfo;
      serverInfo.setData(this.games[index]);
    },
    isTest() {
      return this.$store.state.user !== undefined && this.$store.state.user.state === 3
    }
  },
  // TODO: 删除
  activated() {
    this.$refs.scroll.moveY = 0; // 重置滚动条
  },
  components: {
    ServerInfoDialog,
    ConnectText
  },
};
</script>

