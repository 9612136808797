<template>
  <div class="space">
    <div class="space-item ml-auto" style="height:40px">
      <el-button type="primary" :loading="refreshing" @click="clickRefresh">{{
        refreshing ? "加载中" : "刷新"
      }}</el-button>
    </div>
    <div class="space-item space-full">
      <div class="radius-card" v-loading="loading">
        <el-scrollbar ref="scroll">
          <div class="pa-6">
            <el-table :data="onlineList" ref="table" style="width:100%" border >
              <el-table-column align="center" type="index" width="80" label="编号" />
              <el-table-column align="center" prop="serverName" label="游戏名称" />
              <el-table-column align="center" prop="serverIP" label="服务器IP" width="400" />
              <el-table-column align="center" prop="fixedCount" label="固定数量" :formatter="formatFixedCount" />
              <el-table-column align="center" prop="onlineCount" label="当前在线" />
            </el-table>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import * as Api from "@/api";
export default {
  
  setup() {
    const {onlineList, loading} = Api.getOnlineListRef()
    return {
      onlineList,
      loading
    };
  },
  data() {
    return {
      refreshing: false,
    };
  },
  methods: {
    formatBoolean(_row, _column, cellValue, _index) {
      return cellValue ? 1 : 0;
    },
    refresh() {
      return Api.getOnlineListApi().then(({ data }) => {
        this.onlineList = data.data;
      });
    },
    clickRefresh() {
      this.refreshing = true;
      this.refresh().finally(() => {
        Api.sucessMessage("刷新成功");
        this.refreshing = false;
      });
    },
    formatFixedCount(row, _column, cellValue, _index) {
      return row.isFixedCount ? cellValue : "-";
    },
  },
};
</script>

<style scoped>
.space-full {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>