<template>
  <el-container style="height: 100%;" id="home">
    <el-aside style="width: auto;">
      <ArrowToolBar :value="collapse" :onClick="toggleCollapse" />
      <el-menu
        background-color="#e9eef3"
        text-color="#000"
        @select="select"
        :default-active="activeRoutePath()"
        :collapse="collapse"
        class="el-menu-vertical"
      >
        <template v-if="$store.state.user">
          <el-menu-item index="/game">
            <i class="el-icon-s-platform"></i>
            <template #title><span>游戏列表</span></template>
          </el-menu-item>
          <el-menu-item index="/online">
            <i class="el-icon-menu"></i>
            <template #title><span>在线列表</span></template>
          </el-menu-item>
          <template v-if="$store.state.user.isManager">
            <el-menu-item index="/dashboard">
              <i class="el-icon-pie-chart"></i>
              <template #title><span>仪表盘</span></template>
            </el-menu-item>
            <el-menu-item index="/card">
              <i class="el-icon-postcard"></i>
              <template #title><span>卡片信息</span></template>
            </el-menu-item>
            <el-menu-item index="/user-admin">
              <i class="el-icon-cloudy"></i>
              <template #title><span>用户管理</span></template>
            </el-menu-item>
          </template>

          <el-menu-item index="/user">
            <i class="el-icon-user"></i>
            <template #title><span>用户信息</span></template>
          </el-menu-item>
          <el-menu-item index="/recharge" v-if="!isTest()">
            <i class="el-icon-wallet"></i>
            <template #title><span>充值续费</span></template>
          </el-menu-item>
          <el-menu-item index="/password">
            <i class="el-icon-unlock"></i>
            <template #title><span>修改密码</span></template>
          </el-menu-item>
        </template>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header style="background-color: #fff;color: #333;">
        <h3>H5三端配置</h3>
        <div style="flex-grow: 1"></div>
        <UserInfoToolBar />
      </el-header>

      <el-main>
        <router-view v-slot="{ Component }">
          <keep-alive v-if="$route.meta.keepAlive">
            <component :is="Component" />
          </keep-alive>
          <component :is="Component" v-else />
        </router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import ServerInfoDialog from "@/components/ServerInfoDialog.vue";
import * as StrUtils from "@/utils/strUtils";
import UserInfoToolBar from "@/components/UserInfoToolBar.vue";
import { useToggleState } from "@/states/normal";
import ArrowToolBar from "@/components/ArrowToolBar.vue";
import { CreditCard } from "@element-plus/icons";
import { useStore } from "vuex";
import { onBeforeMount } from "vue";
export default {
  name: "home",
  setup() {
    onBeforeMount(() => useStore().dispatch("userApi"));
    let { flag: collapse, toggle: toggleCollapse } = useToggleState(false);
    return {
      collapse,
      toggleCollapse
    };
  },
  methods: {
    activeRoutePath() {
      return StrUtils.matchDeepPath(this.$route.path, 1);
    },
    select(key) {
      let routerPath = this.$route.path;
      if (
        StrUtils.matchDeepPath(key, 1) === StrUtils.matchDeepPath(routerPath, 1)
      )
        return;
      this.$router.push(key);
    },
    isTest() {
      return (
        this.$store.state.user !== undefined &&
        this.$store.state.user.state === 3
      );
    }
  },

  components: {
    ServerInfoDialog,
    UserInfoToolBar,
    ArrowToolBar,
    CreditCard
  }
};
</script>
<style lang="scss">
#home {
  .el-main {
    background-color: #e9eef3;
  }

  .el-scrollbar,
  .el-container,
  .el-scrollbar {
    height: 100%;
    overflow-y: hidden;
  }

  .el-menu {
    overflow: hidden;
    border: 0 !important;
  }

  .el-aside {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .expand-toolbar {
    height: 60px;
    width: 65px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* 该样式保证菜单展开动画正常，并明确设置宽度 */
  .el-menu-vertical:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }

  .arrow-right {
    & .el-icon-arrow-right {
      font-size: 20px;
      font-weight: bold !important;
    }
    transition: all 0.2s;

    &.reverse {
      transform: rotate(180deg);
    }
  }
}
</style>
