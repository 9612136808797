<template>
  <el-form :model="form" ref="form" style="width: 400px;margin-top:100px;">
    <el-form-item label="充值账号：" prop="account">
      <el-input v-model="form.account"></el-input>
    </el-form-item>
    <el-form-item label="充值卡号：" prop="cardNum">
      <el-input v-model="form.cardNum"></el-input>
    </el-form-item>
    <el-button type="primary" style="width:100%;" @click="recharge">充 值</el-button>
  </el-form>
</template>

<script>
import * as Api from "@/api";
export default {
  data: function () {
    return {
      form: {
        account: "",
        cardNum: "",
      },
    };
  },
  methods: {
    recharge() {
      const form = this.$refs.form;
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)",
      });
      Api.rechargeApi(this.form)
        .then(({ data }) => {
          Api.sucessMessage("充值成功");
        })
        .finally(() => {
          form.resetFields();
          loading.close();
        });
    },
  },
};
</script>

<style>
</style>