<template>
  <div style="height: 60px;background-color:#fff;">
    <div class="expand-toolbar">
      <el-button
        icon="el-icon-arrow-right"
        :class="['awtb-arrow-right', value ? '' : 'reverse']"
        @click="onClick"
        type="text"
      ></el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    onClick: Function 
  },
};
</script>

<style lang="scss">
.awtb-arrow-right {
  & .el-icon-arrow-right {
    font-size: 20px;
    font-weight: bold !important;
  }
  transition: all 0.1s;

  &.reverse {
    transform: rotate(180deg);
  }
}
</style>