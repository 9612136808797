<template>
  <Menu>
    <template #header>
      <el-page-header @back="goBack" title="返回" icon="el-icon-arrow-left">
        <template #content>
          <span style="color:#666;">{{name}}</span>
        </template>
      </el-page-header>
    </template>
    <template #tabs>
      <el-menu background-color="transparent" unique-opened router :default-active="routePath()">
        <el-menu-item class="menu-item" index="basic" :route="{ name: 'basic', params: { id: $route.params.id } }">基本设置</el-menu-item>
        <el-menu-item class="menu-item" index="plugin" :route="{ name: 'plugin', params: { id: $route.params.id } }">内挂设置</el-menu-item>
        <el-submenu index="features" class="menu-item">
          <template #title>
            <span>活动功能</span>
          </template>
          <el-menu-item class="submenu" :route="{ name: 'features-1', params: { id: $route.params.id } }" index="features-1">基础功能</el-menu-item>
          <el-menu-item class="submenu" :route="{ name: 'features-2', params: { id: $route.params.id } }" index="features-2">活动功能</el-menu-item>
        </el-submenu>
        <el-submenu index="custom" class="menu-item">
          <template #title>
            <span>自定义</span>
          </template>
          <el-menu-item class="submenu" :route="{ name: 'custom-1', params: { id: $route.params.id } }" index="custom-1">自定义按钮</el-menu-item>
          <el-menu-item class="submenu" :route="{ name: 'custom-3', params: { id: $route.params.id } }" index="custom-3">新按钮</el-menu-item>
          <el-menu-item class="submenu" :route="{ name: 'custom-2', params: { id: $route.params.id } }" index="custom-2">自定义货币</el-menu-item>
        </el-submenu>
        <el-menu-item class="menu-item" index="other" :route="{ name: 'other', params: { id: $route.params.id } }">其他</el-menu-item>
      </el-menu>
    </template>
    <router-view v-slot="{ Component }">
      <keep-alive v-if="$route.meta.keepAlive">
        <component :is="Component" />
      </keep-alive>
      <component :is="Component" v-else />
    </router-view>
  </Menu>
</template>

<script>
import * as StrUtils from "@/utils/strUtils";
import Menu from "@/components/Menu.vue";
import * as Api from "@/api";
import { useRoute } from "vue-router";
import { onMounted, ref } from "vue";



export default {
  setup() {
    const id = parseInt(useRoute().params.id);
    // TODO: 以后重构
    const name = ref("");
    const setName = () => {
      Api.getGameListApi().then(({ data }) => {
        data.data.forEach((v) => {
          if (v.id === id) {
            console.log(id);
            name.value = v.serverName;
          }
        });
      });
    };
    onMounted(setName)
    return {
      name,
    };
  },
  methods: {
    routePath() {
      return StrUtils.getFilename(this.$route.path);
    },
    goBack() {
      this.$router.push("/game");
    },
  },
  components: {
    Menu,
  },
};
</script>
<style>
.submenu {
  font-size: 12px !important;
  font-weight: normal;
}
.menu-item {
  font-weight: bold;
}
</style>