import _ from 'underscore'

export function matchDeepPath(path: string, deep: number) {
    let strArr = path.split('/').slice(0, deep + 1);
    return strArr.join('/')
}


export function getFilename(path: string) {
    let arr = path.split('/')
    if(arr.length < 1) return path
    if(path.lastIndexOf('/') === path.length - 1) {
        return arr[arr.length - 2]
    }
    return arr[arr.length - 1]
}


export function isEmpty(str: string) {
    return str.trim() == ""
}