<template>
  <el-dialog :title="title" v-model="show" width="700px" top="80px" @close="afterClose">
    <el-form ref="form" :model="form" label-width="80px" size="small" style="width: 300px" :rules="rules">
      <el-form-item label="选择模板：" prop="template" v-show="useTemplate">
        <el-select v-model.number="form.template" placeholder="未选择模板">
          <el-option v-for="(item, index) in templates" :key="item.value" :label="item.label" :value="item.value" @click="changeTemplate(index)">
            <span style="float: left">{{ item.label }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              index
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="游戏名称：" prop="serverName">
        <el-input v-model="form.serverName"></el-input>
      </el-form-item>
      <el-form-item label="服务器地址：" prop="serverAddr">
        <el-input v-model="form.serverAddr" placeholder="请输入域名或ip"></el-input>
      </el-form-item>
      <el-form-item label="端口服务：" prop="port">
        <el-input v-model.number="form.port"></el-input>
      </el-form-item>
      <el-form-item label="验证密码：" prop="passWord">
        <el-input v-model="form.passWord"></el-input>
      </el-form-item>
      <el-form-item label="定时显示：" prop="isShowDate">
        <el-switch v-model="form.isShowDate"> </el-switch>
      </el-form-item>
      <el-form-item label="定时时间：" prop="showDate">
        <el-date-picker v-model="form.showDate" type="datetime" placeholder="选择日期时间" :disabled="!form.isShowDate" value-format="YYYY-MM-DD HH:mm:ss" :default-value="new Date()">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="固定：" prop="isFixedCount">
        <el-switch v-model="form.isFixedCount"> </el-switch>
      </el-form-item>
      <el-form-item label="固定人数：" prop="fixedCount">
        <el-input v-model.number="form.fixedCount" :disabled="!form.isFixedCount"></el-input>
      </el-form-item>
      <el-form-item label="显示循环：" prop="showTop">
        <el-input-number v-model="form.showTop" />
      </el-form-item>
      <el-form-item label="分组：" prop="groupName">
        <el-input v-model="form.groupName"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="submit">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import _ from "underscore";

export default {
  emits: ["post", "put", "changeTemplate"],
  data() {
    function validateServerAddr(rule, value, callback) {
      let regex = /[\/:：]/;
      if (regex.test(value)) {
        callback("请输入域名或ip，例如：aoukj.com 或 192.168.1.1");
      }
      callback();
    }
    return {
      form: {
        id: 0,
        serverName: "",
        serverIP: "",
        port: null,
        passWord: "",
        isShowDate: false,
        showDate: null,
        showTop: 0,
        template: 0,
        groupName: "",
        isFixedCount: false,
        fixedCount: null,
      },
      show: false,
      method: "", // post | put
      title: "",
      useTemplate: false,
      templates: [],
      rules: {
        serverAddr: { validator: validateServerAddr, trigger: "blur" },
      },
    };
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleMethod(this.method);
        }
      });
    },

    handleMethod(method) {
      switch (method) {
        case "post":
          this.$emit("post", this.form);
          return;
        case "put":
          this.$emit("put", this.form);
          return;
      }
    },
    cancel() {
      this.show = false;
      this.$refs.form.resetFields();
    },
    setTitle(title) {
      this.title = title;
    },
    setMethod(method) {
      this.method = method;
    },
    setData(init) {
      if (init) {
        this.$nextTick(() => {
          Object.assign(this.form, init);
        });
      }
    },
    setTemplates(templates) {
      if (templates === null) {
        this.useTemplate = false;
        return;
      }
      this.useTemplate = true;
      this.templates = templates;
    },
    open() {
      this.show = true;
    },
    changeTemplate(index) {
      if (index === 0) {
        this.$refs.form.resetFields();
        return;
      }
      this.$emit("changeTemplate", index - 1);
    },
    afterClose() {
      // this.$refs.form.resetFields();
    },
  },
};
</script>