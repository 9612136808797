import axios, { AxiosResponse } from 'axios'
import { number } from 'echarts'

import * as ApiType from './api.type'
import * as apiHelper from './apiHelper'



const baseUrl = window.baseUrl
export const api = axios.create({
    baseURL: baseUrl,
    headers: {
        "Authorization": apiHelper.getToken()
    },
})

export function loginApi(form: ApiType.LoginRequest): Promise<AxiosResponse<ApiType.NomalResult>> {
    return api.post('/user/login', form)
}

export function registerApi(form: ApiType.RegisterRequest): Promise<AxiosResponse<ApiType.NomalResult>> {
    return api.post('/User/Regist', form)
}

export function changePasswordApi(form: ApiType.RegisterRequest) {
    return api.put('/User/ChangePassword?' + apiHelper.getSearchParams(form)) as Promise<AxiosResponse<ApiType.NomalResult>>
}

export function getUserInfoApi() {
    return api.get('/User/GetUserInfo') as Promise<AxiosResponse<ApiType.UserInfoResult>>
}

export function getGameListApi(): Promise<AxiosResponse<ApiType.GameListResult>> {
    return api.get("/GameList") 
}

export function addGameApi(form: ApiType.Game): Promise<AxiosResponse<ApiType.AnyResult>> {
    // 可以删除，如果后端忽略isConnection参数
    form.isConnection = 0; // default value
    return api.post("/GameList", form)
}

export function modifyGameApi(form: ApiType.Game): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put("/GameList", form)
}

export function deleteGameApi(id: number) {
    return api.delete("/GameList?gameId=" + id) as Promise<AxiosResponse<ApiType.AnyResult>>
}

export function getGameBasicApi(id: string) {
    return api.get('/FunActivity?gameId=' + id) as Promise<AxiosResponse<ApiType.GameBasicResult>>
}

export function getGamePluginApi(id: string): Promise<AxiosResponse<ApiType.GamePluginResult>> {
    return api.get('/GameConfig?gameId=' + id)
    .then((res) => {
        res.data.data.update = false;
        return res
    })
}

export function getGameCustomGoldApi(id: string): Promise<AxiosResponse<ApiType.GameCustomGoldResult>> {
    return api.get('/H5DIYGold?gameId=' + id)
}

export function getGameCustomButtonApi(id: string): Promise<AxiosResponse<ApiType.GameCustomButtonResult>> {
    return api.get('/H5DIYButton?gameId=' + id)
}

export function getGameActivityApi(id: string): Promise<AxiosResponse<ApiType.GameActivityResult>> {
    return api.get('/Acty?gameId=' + id)
}
export function getGameFeaturesApi(id: string): Promise<AxiosResponse<ApiType.GameFeaturesResult>> {
    return api.get('/Fun?gameId=' + id)
}

export function setGameCustomGoldApi(form: ApiType.GameCustomGold): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put('/H5DIYGold', form)
}

export function setGameCustomButtonApi(form: ApiType.GameCustomButton): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put('/H5DIYButton', form)
}

export function setGamePluginApi(id: string, form: ApiType.GamePlugin): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put('/GameConfig?gameId=' + id, form)
}
export function setGamePluginWithSyncApi(id: string, form: ApiType.GamePlugin): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put('/GameConfig/UpdateX?gameId=' + id, form)
}

export function setGameBasicApi(id: string, form: ApiType.GameBasic): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put('/FunActivity?gameId=' + id, form)
}

export function setGameBasicWithSyncApi(id: string, form: ApiType.GameBasic): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put('/FunActivity/UpdateX?gameId=' + id, form)
}

export function setGameFeaturesApi(id: string, form: ApiType.GameFeatures): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put('/Fun?gameId=' + id, form)
}

export function setGameFeaturesWithSyncApi(id: string, form: ApiType.GameFeatures): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put('/Fun/UpdateX?gameId=' + id, form)
}

export function setGameActivityApi(id: string, form: ApiType.GameActivity): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put('/Acty?gameId=' + id, form)
}
export function setGameActivityWithSyncApi(id: string, form: ApiType.GameActivity): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put('/Acty/UpdateX?gameId=' + id, form)
}

export function deleteGamesApi(data: Array<number>): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.post('/GameList/BatchDelete', data)
}

export function setDynamicAllocationApi(value: boolean) {
    let data: Pick<ApiType.UserInfo, 'dynamicDiyUser'> = {
        dynamicDiyUser: value
    }
    return api.post('/User/SetDynamicDiyUser', data)
}

export function rechargeApi(form: ApiType.RechargeRequest): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.post('/User/AccountRecharge', form)
}


export function getUsersApi(pageIndex: number, pageSize: number, userName?: string): Promise<AxiosResponse<ApiType.UsersResult>> {
    return api.post('/Manager/GetUsers', { pageIndex, pageSize, userName })
}

export function syncGameConfigApi(desGameId: number, srcGameId: number) {
    return api.post('/GameList/SyncConfig', { desGameId, srcGameId })
}

export function getUserInfoByNameApi(userName: string): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.get('/Manager/GetUserInfoByName?userName=' + userName)
}

export function setUserInfoApi(form: ApiType.SetUserInfoRequest): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.post('/Manager/UpdateUser', form)
}

export function auditUserApi(userId: number): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.post('/Manager/AuditUser?userId=' + userId)
}
export function auditTestUserApi(userId: number): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.post('/Manager/AuditUserByTest?userId=' + userId)
}

export function getCardsApi(pageIndex: number, pageSize: number): Promise<AxiosResponse<ApiType.CardsResult>> {
    return api.post('/Manager/GetCards', { pageIndex, pageSize })
}

export function generateCardApi(form: ApiType.GenerateCardRequest): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.post('/Manager/PrepaidCard', form)
}

export function getOnlineListApi(): Promise<AxiosResponse<ApiType.OnlineListResult>> {
    return api.post("/GameList/GameListOnlineMsg")
}

export function getGmStatisticApi(starTime: string, endTime: string): Promise<AxiosResponse<ApiType.GmStatisticResult>> {
    const query = apiHelper.getSearchParams({starTime, endTime })
    return api.get('/Statistics/GetGMStatistic?' + query)
}

export function getGMLoginStatisticsApi(starTime: string, endTime: string): Promise<AxiosResponse<ApiType.GmStatisticResult>> {
    const query = apiHelper.getSearchParams({starTime, endTime})
    return api.get('/Statistics/GetGMLoginStatistics?' + query)
}
// @ts-ignore
export function getPlayerLoginStatisticsApi(form: ApiType.PlayerLoginStatisticsReqest): Promise<AxiosResponse<ApiType.PlayerStatisticResult>> {
    return api.post('/Statistics/GetPlayerLoginStatistics', form)
}


export function cloneUserApi(form: { oldUserName: string, newUserName: string}) {
    return api.post('/GameList/CopyGameList', form)
}


export function getGameNewButtonApi(id: string): Promise<AxiosResponse<ApiType.GameNewButtonResult>> {
    return api.get("/H5NEWButton?gameId=" + id);
}
export function setGameNewButtonApi(form: ApiType.GameNewButton): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put("/H5NEWButton", form);
}

export function asyncCustomButton(id: string | number): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put('/H5DIYButton/UpdateX?gameId=' + id);
}

export function asyncCustomGold(id: string | number): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put("/H5DIYGold/UpdateX?gameId=" + id);
}

export function asyncCustomNewButton(id: string | number): Promise<AxiosResponse<ApiType.AnyResult>> {
    return api.put("/H5NEWButton/UpdateX?gameId=" + id);
}

export function setPhoneNumber(value: string) {
    return api.post("/User/SetMobile?mobile=" + value )
}