<template>
  <el-dialog
    title="列表复制"
    width="400px"
    :model-value="modelValue"
    @update:model-value="update"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
      style="width: 300px"
      class="mx-auto"
    >
      <el-form-item label="老帐号：" prop="oldUserName">
        <el-input v-model="form.oldUserName" name="oldUserName" />
      </el-form-item>
      <el-form-item label="新账号：" prop="newUserName">
        <el-input v-model="form.newUserName" @keydown.enter="submit" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="submit"
          >确认</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
export default {
  emits: ["update:model-value", "submit"],
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      form: {
        oldUserName: "",
        newUserName: ""
      },
      rules: {
        oldUserName: [{ message: "请填写老用户", trigger: "blur", required: true }],
        newUserName: [{ message: "请填写新用户", trigger: "blur", required: true }]
      }
    };
  },
  methods: {
    update(v) {
      this.$emit("update:model-value", v);
    },
    submit() {
      
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('submit', this.form);
          this.form.oldUserName = '';
          this.form.newUserName = '';
        }
      });
    }
  }
};
</script>
