<template>
  <span :class="getClassName()">
    <i
      class="iconfont icon-dot"
      style="font-size:20px;vertical-align:middle;"
    ></i>
    <span style="vertical-align:middle;line-height:25px;">{{ data[type].text }}</span>
  </span>
</template>

<script>
export default {
  props: {
    type: {
        type: Number,
        default: 0,
    },
    data: Object
  },
  methods: {
      getClassName() {
          if(this.type === 1) {
              return 'connect'
          }
          if(this.type === 2) {
              return 'disconnect'
          }
          return 'dead'
      }
  }
};
</script>
<style scoped>
.connect {
  color: #67c23a;
}

.disconnect {
  color: #f56c6c;
}

.dead {
  color: #666;
}
</style>