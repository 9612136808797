import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import User from '@/views/User.vue'
import Game from '@/views/Game.vue'
import Password from '@/views/Password.vue'
import GameSettings from '@/views/game-settings/GameSettings.vue'
import UserAdmin from '@/views/UserAdmin.vue'
import AdminUserSettings from '@/views/user-admin-settings/AdminUserSettings.vue'
import Card from '@/views/Card.vue'
import Online from '@/views/Online.vue'
import Recharge from '@/views/Recharge.vue'
import Dashboard from '@/views/Dashboard.vue'

// 三级路由
let gameSettingsChildren: RouteRecordRaw[] = [
  {
    path: 'basic',
    name: 'basic',
    component: () => import(/* webpackChunkName: "game-settings" */ '../views/game-settings/settings/Basic.vue'),
  },
  {
    path: 'plugin',
    name: 'plugin',
    component: () => import(/* webpackChunkName: "game-settings" */ '../views/game-settings/settings/Plugin.vue'),
  },
  {
    path: 'features-1',
    name: 'features-1',
    component: () => import(/* webpackChunkName: "game-settings" */ '../views/game-settings/settings/Features-1.vue'),
  },
  {
    path: 'features-2',
    name: 'features-2',
    component: () => import(/* webpackChunkName: "game-settings" */ '../views/game-settings/settings/Features-2.vue'),
  },
  {
    path: 'custom-1',
    name: 'custom-1',
    component: () => import(/* webpackChunkName: "game-settings" */ '../views/game-settings/settings/Custom-1.vue'),
  },
  {
    path: 'custom-2',
    name: 'custom-2',
    component: () => import(/* webpackChunkName: "game-settings" */ '../views/game-settings/settings/Custom-2.vue'),
  },
  {
    path: 'custom-3',
    name: 'custom-3',
    component: () => import(/* webpackChunkName: "game-settings" */ '../views/game-settings/settings/Custom-3.vue'),
  },
  {
    path: 'other',
    name: 'other',
    component: () => import(/* webpackChunkName: "game-settings" */ '../views/game-settings/settings/Other.vue'),
  },
]


const userAdminSettingschidren: RouteRecordRaw[] = [
  {
    path: 'info',
    name: 'info',
    component: () => import(/* webpackChunkName: "user-settings" */ '../views/user-admin-settings/settings/UserInfo.vue'),
  }
]


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/game',
    component: Home,
    children: [
      { path: 'user', component: User },
      { path: 'game', component: Game  },
      { path: 'online', component: Online },
      { path: 'game/settings/:id', component: GameSettings, children: gameSettingsChildren, redirect: {name: 'basic'}},
      { path: 'user-admin', component: UserAdmin  },
      { path: 'password', component: Password },
      { path: 'user-admin/settings/:account', component: AdminUserSettings, children:  userAdminSettingschidren, redirect: {name: 'info'}},
      { path: 'card', component: Card },
      { path: 'recharge', component: Recharge},
      { path: 'dashboard', component: Dashboard}
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
