<template>
  <div class="uitb-container" v-if="$store.state.user">
    当前登录用户：
    <el-popover placement="bottom" v-model:visible="active" :width="200" trigger="hover" >
      <template #reference>
        <div>
          <el-button class="uitb-hover-text">{{ getUser().account }}&nbsp;&nbsp;<i class="el-icon-arrow-down"></i></el-button>
        </div>
      </template>
      <el-space wrap fill>
        <div class="uitb-username pl-2">{{ getUser().account }}</div>
        <span class="uitb-qq pl-2">{{ getUser().qq }}</span>
        <el-divider style="margin: 8px 0;" />
        <div class="uitb-item-btn" @click="onUserInfo">用户信息</div>
        <div class="uitb-item-btn" @click="onPassword">修改密码</div>
        <el-divider style="margin: 8px 0;" />
        <el-button style="width: 100%" type="danger" @click="onLogOut">注销</el-button>
      </el-space>
    </el-popover>
  </div>
</template>

<script>
import * as Api from "@/api";
export default {
  data: function () {
    return {
      active: false,
    };
  },
  methods: {
    close() {
      this.active = false;
    },
    onUserInfo() {
      this.close();
      this.$router.push("/user");
    },

    onPassword() {
      this.close();
      this.$router.push("/password");
    },
    onLogOut() {
      this.close();
      this.logout();
    },

    logout() {
      this.$confirm("此操作将注销账户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        Api.logout();

        this.$router.push("/login");
      });
    },
    changeDyn(value) {
      Api.setDynamicAllocationApi(value);
    },
    getUser() {
      return this.$store.state.user;
    },
  },
};
</script>

<style lang="scss">
.uitb-container {
  display: flex;
  align-items: center;
}

.uitb-username {
  font-size: 18px;
  font-weight: bold;
  box-sizing: border-box;
}

.uitb-qq {
  font-size: 14px;
  color: #666;
  box-sizing: border-box;
}

%uitb-item {
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  box-sizing: border-box;
}
.uitb-item-btn {
  width: 100%;
  display: inline-block;
  @extend %uitb-item;
  cursor: pointer;
  &:hover {
    color: var(--el-color-primary);
    background-color: var(--el-color-primary-light-8);
  }
}
.uitb-hover-text {
  font-size: 16px !important;
  font-weight: bold !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
</style>