<template>
  <div class="space">
    <div class="space-item">
      <el-button class="float-right mx-4" @click="openCardGenerationDialog">生成卡片</el-button>
    </div>
    <div class="space-item flex flex-1 flex-column">
      <div class="radius-card" v-loading="loading">
        <el-scrollbar ref="scroll">
          <div class="pa-6">
            <el-table :data="cards" border ref="table" style="width: 100%">
              <el-table-column align="center" prop="id" label="编号" />
              <el-table-column align="center" prop="makeName" label="创建者" />
              <el-table-column align="center" prop="maxUser" label="最大用户" />
              <el-table-column align="center" prop="price" label="价格" />
              <el-table-column align="center" prop="makeDate" label="创建日期" />
              <el-table-column align="center" prop="useDate" label="使用日期" />
              <el-table-column align="center" prop="useName" label="使用用户" />

              <el-table-column align="center" width="250" label="操作">
                <template #default="scope">
                  <el-button @click="openCardNumberDialog(scope.$index)" size="mini" @click.stop>查看卡号</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="flex justify-content-center align-item-center">
            <el-pagination layout="prev, pager, next" :total="total" :page-size="pageSize" v-model:currentPage="pageIndex" @current-change="changePage" hide-on-single-page>
            </el-pagination>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
  <CardNumberDialog ref="cardNumber" :show="showCardNumber" :close="() => (showCardNumber = false)" />
  <CardGenerationDialog ref="cardGeneration" :show="showCardGeneration" :close="() => (showCardGeneration = false)" @submit="generateCard" />
</template>

<script>
import * as Api from "@/api";
import CardNumberDialog from "@/components/CardNumberDialog.vue";
import CardGenerationDialog from "@/components/CardGenerationDialog.vue";
export default {
  name: "card",
  data() {
    return {
      showCardNumber: false,
      showCardGeneration: false,
      cards: [],
      total: 0,
      pageSize: 10,
      pageIndex: 1,
      loading: true,
    };
  },
  methods: {
    refresh() {
      this.loading = true;
      return Api.getCardsApi(this.pageIndex, this.pageSize).then(({ data }) => {
        this.cards = data.data.cards;
        this.total = data.data.total;
      })
      .finally(() => {
        this.loading = false
      })
    },
    refreshWithLoading() {
      this.refresh();
    },
    openCardNumberDialog(index) {
      this.showCardNumber = true;
      this.$refs.cardNumber.setCardNumber(this.cards[index].card);
    },
    openCardGenerationDialog() {
      this.showCardGeneration = true;
    },
    changePage(value) {
      this.pageIndex = value;
      this.refreshWithLoading();
    },
    generateCard(form) {
      const loading = this.$loading({
        lock: true,
        background: "rgba(0, 0, 0, 0.7)",
      });
      Api.generateCardApi(form)
        .then(({ data }) => {
          this.$notify({
            title: "生成卡片",
            message: data.data,
            type: "success",
          });
        })
        .finally(() => {
          loading.close();
          this.showCardGeneration = false;
        });
    },
  },
  mounted() {
    this.refresh();
  },
  components: {
    CardNumberDialog,
    CardGenerationDialog,
  },
};
</script>