<template>
  <div style="position: relative">
    <el-page-header @back="goBack" title="返回" icon="el-icon-arrow-left"></el-page-header>
    <router-view v-slot="{Component}">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
import Menu from "@/components/Menu.vue";
export default {
  components: { Menu },
  methods: {
    goBack() {
      this.$router.push('/user-admin')
    },
  },
};
</script>

<style>
</style>