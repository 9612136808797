import { createStore } from 'vuex'
import {UserInfo} from '@/api/api.type'
import * as Api from '@/api'

export default createStore<{user: UserInfo | undefined}>({
  state: {
    user: undefined,
  },
  mutations: {
    user(state, user: UserInfo) {
      state.user = user
    }
  },
  actions: {
    userApi(context) {
      Api.getUserInfoApi()
      .then(({data}) => {
        context.commit('user', data.data)
      })
    }
  },
  modules: {
  }
})
