<template>
  <div class="container">
    <slot name="header"></slot>
    <div class="nav">
      <div class="tabs">
        <slot name="tabs"></slot>
      </div>
      <div class="contents">
          <slot></slot>
      </div>
    </div>
  </div>
</template>

<style scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.nav {
  flex: 1;
  display: flex;
}

.tabs {
  text-align: center;
  width: 200px !important;
  padding-top: 200px;
  box-sizing: border-box;
  margin-right: 32px;
}

.contents {
  flex: 1;
  overflow: hidden;
}
</style>