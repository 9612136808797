import * as echarts from "echarts";
import _ from 'underscore'

export function initGmStatisticSetting(charts: echarts.ECharts) {
    let option: echarts.EChartsOption = {
        title: {
            text: 'GM统计',
        },
        tooltip: {
            trigger: "axis",
            axisPointer: { type: "cross" },
        },
        legend: {
            orient: 'vertical',
            right: 10,
            top: 'center'
        },
        dataset: {

        },
        xAxis: {
            type: "category",
            axisTick: {
                alignWithLabel: true,
            },
        },
        yAxis: {
        },
        series: [
            {
                itemStyle: {
                    
                },
                type: "bar",
                large: true
            },
            {
                type: "bar",
                large: true
            },
            {
                type: "line",
            },
        ],
    }
    return new ChartSetting(charts, option)
}
export function initGmLoginStatisticSetting(charts: echarts.ECharts) {
    let option: echarts.EChartsOption = {
        title: {
            text: 'GM登录统计',
        },
        tooltip: {
            trigger: "axis",
            axisPointer: { type: "cross" },
        },
        legend: {
            orient: 'vertical',
            right: 10,
            top: 'center'
        },
        dataset: {

        },
        xAxis: {
            type: "category",
            axisTick: {
                alignWithLabel: true,
            },
        },
        yAxis: {
        },
        series: [
            {
                type: "bar",
                large: true
            },
            {
                type: "bar",
                large: true,
            },
            {
                type: "bar",
                large: true
            },
        ],
    }
    return new ChartSetting(charts, option)
}
export function initPlayerLoginStatisticSetting(charts: echarts.ECharts) {
    let option: echarts.EChartsOption = {
        title: {
            text: '玩家登录统计',
        },
        tooltip: {
            trigger: "axis",
            axisPointer: { type: "cross" },
        },
        legend: {
            orient: 'vertical',
            right: 10,
            top: 'center'
        },
        dataset: {

        },
        xAxis: {
            type: "category",
            axisTick: {
                alignWithLabel: true,
            },
        },
        yAxis: {
        },
        series: [
            {
                type: "bar",
                large: true
            },
            {
                type: "bar",
                large: true,
            },
            {
                type: "bar",
                large: true
            },
            {
                type: "line",
            },
        ],

    }
    return new ChartSetting(charts, option)
}

export class ChartSetting {
    private echarts: echarts.ECharts
    public option: echarts.EChartsOption;
    constructor(echarts: echarts.ECharts, option: echarts.EChartsOption) {
        this.echarts = echarts
        this.option = option
    }

    render() {
        this.echarts.setOption(this.option)
    }

    setSource(source: (string | number)[][]) {
        (this.option.dataset as echarts.DatasetComponentOption).source = source
    }

    setSourceWithUnion(first: (string | number)[], ...data: (string | number)[][]) {
        const result = _.union([first], _.zip(...data))
        this.setSource(result)
    }

    dispose() {
        this.echarts.dispose();
    }
}


