<template>
  <router-view/>
</template>
<style>
#app {
  height: 100%;
  background-color: #e9eef3;
}
.el-form-item__label:before {
  display: none !important;
}
</style>
