<template>
  <gm-statistic/>
  <gm-login-statistic/>
  <player-login-statistic />
</template>

<script>
import GmStatistic from '@/components/GmStatistic.vue'
import GmLoginStatistic from '@/components/GmLoginStatistic.vue'
import PlayerLoginStatistic from '@/components/PlayerLoginStatistic.vue'
import * as Api from '@/api'
export default {
  setup() {
    // const {games} = Api.getGameListRef()
    // return {
    //   games
    // }
  },
  components: {
    GmStatistic,
    GmLoginStatistic,
    PlayerLoginStatistic
  }
}
</script>

<style>

</style>