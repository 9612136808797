import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './static/style/base.scss'
import 'element-plus/lib/theme-chalk/index.css'
import ElementPlus from 'element-plus'
import "@/static/font/iconfont.css"
import '@/static/font/font_t27tioe584/iconfont.css'

createApp(App).use(store).use(router).use(ElementPlus).mount('#app')