<template>
  <el-dialog
    title="编辑"
    v-model="show"
    :before-close="beforeClose"
    @close="closeAfter"
    width="400px"
  >
    <el-form
      ref="form"
      :model="form"
      label-width="80px"
      style="width: 300px"
      class="mx-auto"
    >
      <el-form-item label="最大人数：" prop="maxUser">
        <el-input v-model.number="form.maxUser" />
      </el-form-item>
      <el-form-item label="价格：" prop="price">
        <el-input v-model.number="form.price" @keydown.enter="submit"/>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="submit"
          >确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  emits: ["submit"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      form: {
        maxUser: null,
        price: null
      },
    };
  },
  methods: {
    beforeClose(done) {
      this.close();
      done();
    },
    closeAfter() {
      this.$refs.form.resetFields();
    },
    submit() {
      this.$emit('submit', this.form)
    }
  },
};
</script>