<template>
  <el-card class="radius-20 mb-4" v-loading="loading">
    <div style="position:relative;">
      <div style="text-align:right;">
        <el-date-picker v-model="value" type="daterange" size="small" :clearable="false" value-format="YYYY-MM-DD" :default-time="[new Date(), new Date()]" :disabled="disableCustomDate" @change="setStatistic" />
        &nbsp;
        <el-select v-model="day" size="small" style="width:120px;" @change="changeSelect">
          <el-option label="最近七天" :value="7"></el-option>
          <el-option label="最近三十天" :value="30"></el-option>
          <el-option label="自定义" :value="-1"></el-option>
        </el-select>
      </div>

      <div style="width:auto;height:400px;" id="gmLoginStatistic" ref="gmLoginStatistic"></div>
    </div>
  </el-card>
</template>

<script>
import * as echarts from "echarts";
import { initGmLoginStatisticSetting } from "@/utils/chartOptions";
import { ref } from "vue";
import * as Api from "@/api";
export default {
  props: {
    day: {
      type: Number,
      default: 7,
    },
  },
  customData: {
    statisticSetting: undefined,
  },
  setup() {
    return {
      value: ref([]),
      disableCustomDate: ref(true),
      loading: ref(true),
    };
  },
  methods: {
    setStatistic() {
      this.loading = true
      Api.getGMLoginStatisticsApi(this.value[0], this.value[1]).then(
        (response) => {
          const data = response.data.data;
          this.$options.customData.statisticSetting.setSourceWithUnion(
            ["date", "GM登录IP", "GM登录PV", "GM登录UV"],
            data.listdate,
            data.gMStatistic["GM登录IP"],
            data.gMStatistic["GM登录PV"],
            data.gMStatistic["GM登录UV"]
          );
          this.$options.customData.statisticSetting.render();
        }
      )
      .finally(this.loaded)
    },
    changeSelect() {
      if (this.day < 0) {
        this.disableCustomDate = false;
        return;
      }
      this.disableCustomDate = true;
      this.value = Api.processDateRange(this.day);
      this.setStatistic();
    },
    loaded() {
      if (this.loading) this.loading = false;
    },
  },
  mounted() {
    let chart = echarts.init(this.$refs.gmLoginStatistic);
    this.$options.customData.statisticSetting =
      initGmLoginStatisticSetting(chart);
    this.changeSelect();
  },
  unmounted() {
    this.$options.customData.statisticSetting.dispose();
    this.$options.customData.statisticSetting = undefined
  },
};
</script>