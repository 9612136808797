<template>
  <el-dialog title="卡号" :before-close="beforeClose" v-model="show" width="430px" top="300px">
    <code class="code">
      {{cardNumber}}
    </code>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    close:{
      type: Function,
      required: true,
    },
  },
  data(){
    return {
      cardNumber: ''
    }
  },
  methods: {
    beforeClose(done) {
      this.close()
      done()
    },
    setCardNumber(cardNumber) {
      this.cardNumber = cardNumber
    }
  }
}
</script>